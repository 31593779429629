<template>
  <div>
    <div v-for="anggota in list_anggota" :key="anggota.id">
      <img
        :src="
          'https://api.qrserver.com/v1/create-qr-code/?size=120x120&data=https://form.xtcindonesia.org/authenticity/' +
          anggota.code
        "
        width="120"
        alt=""
        srcset=""
        class="mt-3"
      />
      <h4 class="mt-4">{{ anggota.code }}</h4>
    </div>
  </div>
</template>

<script>
import { qrCodeService } from "@/services";
export default {
  data() {
    return {
      list_anggota: [],
      search: {
        status: "APPROVED",
      },
    };
  },
  mounted() {
    qrCodeService
      .printOutUnusedCode()
      .then((data) => {
        this.list_anggota = data;
      })
      .catch((error) => {
        this.$toast.error(`Gagal memuat data: ${error.message}`);
      });
  },
};
</script>

<style lang="scss" scoped></style>
